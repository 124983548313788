$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/"

@import "~bootstrap-sass/assets/stylesheets/bootstrap"
@import "~react-widgets/scss/styles"

// generic

body
  padding-top: 2px

.nav-pills > li:not(:last-child)
  padding-right: 5px

.row
  margin-bottom: 0
  margin-top: 15px
  margin-left: -15px
  margin-right: -15px

.table
  margin: 0

.full-height
  height: calc(100vh - 64px)

.flex-row-container
  display: flex
  flex-direction: column

.flex-row
  flex-grow: 1
  padding-top: 15px
  padding-bottom: 15px

.flex-column-container
  display: flex
  flex-direction: row

.flex-column
  flex-grow: 1
  padding-top: 15px
  padding-bottom: 15px

.flex-space-between
  justify-content: space-between

.flex-center-items
  align-items: center

.highlight
  border: dashed 1px #000

ul.list-inline
  margin-left: 0
  margin-bottom: 0

  > *
    padding-left:  0
    padding-right: 0
    margin-right: 5px

  > :last-child
    padding-right: 0
    margin-right: 0

ul.pagination
  margin-top:    10px
  margin-bottom: 10px

table.table-fixed
  table-layout: fixed

table.vertical-align-center td
  vertical-align: middle !important

.table-responsive
  border: none

.inline-selection .inputs
  display: flex

  .input
    width: 100%

  .submit
    width: 100px
    margin-left: 10px

.panel-body :not(.rw-dropdown-list) :first-child:not([type=checkbox]):not([type=radio])
  margin-top: 0

.panel-body > :last-child, .modal-body > :last-child
  margin-bottom: 0

.modal-body.scrollable
  max-height: calc(100vh - 176px)
  overflow: auto

form > .form-group:last-child
  margin-bottom: 0

form .form-group .help-block
  margin-bottom: 0

  p:last-child
    margin-bottom: 0

.has-error .rw-widget,
.has-error .rw-widget.rw-state-focus,
.has-error .rw-widget.rw-state-focus:hover,
.has-error .rw-widget .rw-widget-container
  border-color: #a94442

.radio.disabled label
  color: #aaa

.nav > li.disabled > a
  color: #aaa
  border: 1px solid #aaa

.nav-pills > li > a, .nav-pills > li > a:hover, .nav-pills > li > a:focus
  border: 1px solid #337ab7

.navbar, .panel, .alert
  margin-bottom: 15px

.panel-heading
  position: relative

.navbar-form .form-group
  position: relative

.row div[class^='col-']:last-child .alert:last-child
  margin-bottom: 0

// auth

@media (min-width: $screen-sm-min)
  .auth-panel, .auth-alert, .contact-info
    max-width: 620px
    margin-left: auto
    margin-right: auto

  .contact-info.profile
    max-width: 1270px
    margin-left: auto
    margin-right: auto

.auth-alert, .contact-info
  margin-bottom: 0

.auth-panel.left, .auth-alert.left, .contact-info.left
  margin-left: auto
  margin-right: 0

.auth-panel.right, .auth-alert.right, .contact-info.right
  margin-left: 0
  margin-right: auto
